@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Light-Italic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Book-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Medium-Italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-SemiBold-Italic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton-Bold-Italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Playlist Script";
  src: url("../fonts/Playlist-Script.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Austin";
  src: url("../fonts/AustinCyr-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ArnoPro";
  src: url("../fonts/ArnoPro-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}