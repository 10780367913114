@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .header {
    &__text {
      margin: 0 0 0 70px;
    }
  }
  .site {
    &-logo {
      [class*="--home"] & {
        margin: 0 0 45px 15px;
      }
    }
    &-box {
      &--info {
        max-width: none !important;
      }
      &__deco {
        display: none;
      }
    }
  }
  .section {
    &--nutritional {
      padding: 20px 0 30px 0;
      .main {
        gap: 0 20px;
      }
    }
  }
  .info {
    &-logo {
      width: 30%;
      margin: 50px 0 0 0;
    }
    &-text {
      width: 64%;
      min-height: 0;
      margin: 50px 0 0 auto;
    }
    &-hold {
      padding: 50px 0;
      gap: 50px 40px;
    }
  }
  .nutritional {
    &-text {
      flex: 0 0 100%;
    }
    &-col {
      width: 174px;
      margin: 0;
      flex: 1 1 0;
    }
  }
  .tabs {
    &-nav {
      display: none;
      &__links {
        border: 1px solid var(--tuna);
        padding: 16px 50px 12px 20px;
        margin: 0 0 15px 0;
        &--mobile {
          display: block;
        }
      }
    }
    &-hold {
      width: 100%;
    }
    &-panels {
      &.open {
        padding: 20px 0 0 0;
      }
      &__text {
        padding: 0;
      }
    }
  }
  .locations {
    &-list {
      padding: 38px 30px 20px 30px;
    }
  }
  .promo {
    &-1 &__text,
    &-3 &__text {
      inset: 10px auto 10px 30px;
    }
    &-2,
    &-4,
    &-5,
    &-6,
    &-7,
    &-8,
    &-9 {
      padding: 38px 30px;
    }
    &-2 {
      gap: 10px 30px;
    }
    &-4 {
      gap: 10px 50px;
    }
    &-transparent {
      padding: 38px 0;
    }
    &-9 &__text {
      column-gap: 30px;
    }
  }
}