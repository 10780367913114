@media (max-width: $mobile-max) {
  body {
    min-width: 360px;
  }
  .header {
    &--home {
      min-height: 0;
      background: url(../img/bg-nutritional.jpg) repeat center center / cover;
      padding: 22px 0 0 0;
    }
    &--inner {
      .btn {
        font: 16px/1.2 var(--primary-font);
        padding: 10px 14px 6px 14px;
      }
    }
    &__text {
      max-width: none;
      margin: 0;
    }
    &__image {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .site {
    &-logo {
      [class*="--home"] & {
        max-width: 132px;
        margin: 0 0 30px 0;
      }
      [class*="--inner"] & {
        max-width: 132px;
      }
    }
    &-hamburger {
      [class*="--home"] & {
        top: 38px;
      }
    }
    &-box {
      &--info {
        width: 100%;
        max-width: none !important;
      }
      &__deco {
        display: none;
      }
    }
  }
  .nav {
    &-main {
      max-width: none;
      border-radius: 0 0 20px 20px;
      padding: 70px 20px 20px 20px;
      &__links {
        padding: 10px 0;
      }
    }
  }
  .body {
    padding: 0 0 20px 0;
  }
  .section {
    &--nutritional {
      padding: 20px 0 30px 0;
      .main {
        gap: 30px 20px;
      }
    }
    &--steaks {
      min-height: 330px;
      margin: 0 0 60px 0;
    }
    &--minced {
      color: var(--elements-color);
    }
    &--barbecue {
      min-height: 400px;
      margin: 0;
    }
    &--knife {
      min-height: 460px;
      margin: 0 0 60px 0;
    }
  }
  .info {
    &-logo {
      width: auto;
      max-width: 75%;
      margin: 30px auto 20px auto;
    }
    &-text {
      width: 100%;
      min-height: 0;
      margin: 0;
    }
    &-hold {
      padding: 50px 0 40px 0;
      gap: 50px 40px;
    }
    &-columns {
      column-count: 1;
      &--multiple {
        column-count: 1;
      }
      &--center {
        grid-template-columns: 1fr;
      }
      &__wrap {
        &--list-1 {
          ol {
            li {
              padding: 0 0 25px 40px;
            }
          }
        }
      }
    }
  }
  .hero {
    &-steaks {
      &__text {
        margin: 0 auto;
      }
    }
    &-barbecue {
      &__text {
        margin: 0 auto;
      }
    }
    &-knife {
      &__text {
        text-align: center;
        margin: 0 auto;
        h3 {
          margin: -10px 0 30px 0;
        }
      }
    }
  }
  .nutritional {
    &-text {
      flex: 0 0 100%;
      h2 {
        margin: 0;
      }
    }
    &-col {
      margin: 0;
      flex: 1 1 calc(50% - 10px);
    }
  }
  .tabs {
    &-nav {
      display: none;
      &__links {
        border: 1px solid var(--tuna);
        padding: 16px 50px 12px 20px;
        margin: 0 0 15px 0;
        &--mobile {
          display: block;
        }
      }
    }
    &-panels {
      &.open {
        padding: 20px 0 0 0;
      }
      &__text {
        padding: 0;
      }
    }
  }
  .animal {
    &-parts {
      $parent: &;
      &--pork {
        #{$parent}__hold {
          column-count: 1;
        }
      }
      &--beef {
        margin: 0 0 10px 0;
        gap: 20px;
        #{$parent}__image {
          max-width: 55%;
        }
      }
      &--chicken {
        flex-wrap: wrap;
        #{$parent}__hold {
          padding: 0;
          flex: 0 0 100%;
        }
        #{$parent}__image {
          max-width: 60%;
        }
      }
    }
  }
  .description {
    &-block {
      &--beef {
        background: url(../img/description-jautiena.jpg) no-repeat center center / 100% auto;
      }
      &--meet {
        background: url(../img/description-mesa.jpg) no-repeat center calc(50% - 20px) / 100% auto;
      }
    }
  }
  .locations {
    &-list {
      padding: 22px 20px 0 20px;
      ol:not([class]) {
        column-count: 1;
      }
    }
  }
  .promo {
    &-1 &__image,
    &-3 &__image {
      display: none;
    }
    &-1 &__text,
    &-3 &__text {
      max-width: none;
      position: relative;
      inset: auto;
      h3 {
        margin-left: 0;
      }
    }
    &-3 &__headline {
      margin-left: 0;
    }
    &-2,
    &-4,
    &-5,
    &-6,
    &-7,
    &-8,
    &-9,
    &-transparent {
      padding: 25px 20px 30px 20px;
      gap: 20px;
    }
    &-2 {
      margin: 20px 0 40px 0;
    }
    &-4 {
      margin: 20px 0 40px 0;
    }
    &-5 {
      margin: 20px 0 40px 0;
    }
    &-6 {
      margin: 20px 0 25px 0;
    }
    &-7 {
      margin: 20px 0 25px 0;
    }
    &-8 {
      margin: 10px 0 35px 0;
    }
    &-9 {
      margin: 10px 0 35px 0;
    }
    &-transparent {
      padding: 25px 0 30px 0;
      margin: 0 0 30px 0;
    }
    &-2 &__text,
    &-4 &__text,
    &-5 &__text,
    &-6 &__text,
    &-7 &__text,
    &-8 &__text,
    &-9 &__text,
    &-transparent &__text {
      flex: 0 0 100%;
      &__columns {
        column-count: 1;
      }
    }
    &-8 &__text {
      text-align: center;
    }
    &-9 &__text {
      column-count: 1;
    }
    &-2 &__image,
    &-4 &__image,
    &-5 &__image,
    &-6 &__image,
    &-7 &__image,
    &-8 &__image,
    &-9 &__image,
    &-transparent &__image {
      max-width: 100%;
      margin: 0 auto;
    }
    &-12 &__column {
      width: 100%;
      max-width: none;
    }
  }
  .flavors {
    &-pepper {
      &__list {
        padding: 20px 30px 30px 30px;
      }
      &__block {
        flex-wrap: wrap;
        &--top {
          padding: 40px 0 10px 0;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-flow: column nowrap;
        }
        &__text {
          &--1 {
            margin: 0 30px;
          }
          &--2 {
            max-width: none;
            margin: 0 30px;
          }
        }
        &__info {
          max-width: none;
          padding: 33px 40px 12px 40px;
          margin: 30px 30px 0 30px;
        }
        &__image {
          margin: 30px 30px 0 30px;
        }
      }
    }
  }
  .tricks {
    &-text {
      margin: 0 0 50px 0;
    }
    &-list {
      padding: 0 0 20px 0;
      counter-reset: li;
      grid-template-columns: 1fr;
      display: grid;
      gap: 30px;
      &__items {
        align-items: flex-start;
        display: flex;
        gap: 10px;
        &--1 {
          padding: 0;
        }
        &--2 {
          padding: 0;
        }
      }
    }
  }
  .random {
    &-1 {
      text-align: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    &-1 &__text {
      width: 100%;
      max-width: none;
    }
    &-1 &__image {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}