// media queries scss
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1229px;
$desktop-min: 1230px;

// helpers loop variables scss
$padding-top-bottom-from: 0;
$padding-top-bottom-to: 0;
$padding-top-bottom-step: 2;

$padding-left-right-from: 0;
$padding-left-right-to: 0;
$padding-left-right-step: 2;

$margin-top-bottom-from: 0;
$margin-top-bottom-to: 40;
$margin-top-bottom-step: 2;

$margin-left-right-from: 0;
$margin-left-right-to: 0;
$margin-left-right-step: 2;

// helpers loop padding and margin top left right bottom scss
$helpers-xl: false; // desktop
$helpers-sm: false; // tablet
$helpers-xs: false; // mobile

:root {
  /* colors with hex values */
  --black: #000;
  --white: #fff;
  --slate-gray: #778192;
  --casper: #AABBCA;
  --silver: #bababa;
  --tuna: #393A42;
  --cotton-seed: #C5BFB9;
  --international-orange: #FF5A0B;
  --green-smoke: #9BA359;
  --tradewind: #58BBA9;
  --eucalyptus: #2BA44F;
  --matterhorn: #55414D;
  --flame-pea: #DF5D2F;
  --blue-chill: #128FA9;
  --gun-powder: #46485C;
  --mine-shaft: #2D2D2D;
  --shark: #232327;
  --abbey: #4f4f52;
  --alabaster: #fafafa;
  --monza: #d60017;

  /* reassign color vars to semantic color scheme */
  --text-color: var(--white);
  --secondary-text-color: var(--slate-gray);
  --elements-color: var(--white);
  --deco-color: var(--international-orange);
  --secondary-deco-color: var(--tradewind);
  --tertiary-deco-color: var(--blue-chill);

  /* font */
  --primary-font: "Campton", sans-serif;
  --secondary-font: "Playlist Script", sans-serif;
  --Austin: "Austin", serif;
  --ArnoPro: "ArnoPro", serif;

  /* other elements */
  --primary-transition: .2s linear;
  --border-color: var(--silver);
  --primary-radius: 6px;
  --error-color: var(--monza);
  --gutter: 15px;

  /* media queries css */
  --mobile-max: #{$mobile-max};
  --tablet-min: #{$tablet-min};
  --tablet-max: #{$tablet-max};
  --desktop-min: #{$desktop-min};
}