// imports top
@import "config";
@import "normalize";
@import "reset";
@import "fonts";
@import "typography";
@import "swiper-bundle.min";
@import "plugins";
body,
html {
  height: 100%;
}
body {
  color: var(--text-color);
  font: 16px/1.3 var(--primary-font);
  background: var(--black);
}
.wrapper {
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}
.main {
  width: 100%;
  max-width: 1230px;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  margin: 0 auto;
  &--tight {
    max-width: 826px;
  }
  &--wide {
    max-width: 1030px;
  }
  &--flex {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
}
.header {
  isolation: isolate;
  position: relative;
  z-index: 1;
  &--home {
    min-height: 680px;
    background: url(../img/header-home.jpg) no-repeat center center;
    padding: 70px 0 0 0;
  }
  &--inner {
    padding: 22px 0;
  }
  &__text {
    max-width: 490px;
    margin: 0 0 0 100px;
    &__row {
      padding: 0 0 25px 0;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
    &__signature {
      margin: 0 0 0 auto;
    }
  }
}
.site {
  &-logo {
    margin: 0 auto 0 0;
    display: inline-block;
    img {
      max-width: 100%;
    }
    [class*="--home"] & {
      margin: 0 0 45px 45px;
    }
    [class*="--inner"] & {
      max-width: 195px;
    }
  }
  &-hamburger {
    height: 19px;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity;
    margin: 0 0 0 15px;
    position: relative;
    z-index: 2;
    align-items: center;
    display: inline-flex;
    [class*="--home"] & {
      transform: translateX(-100%);
      margin: 0 0 0 auto;
      position: absolute;
      top: 50px;
    }
    &__box {
      width: 29px;
      height: 19px;
      position: relative;
      display: block;
    }
    &__inner {
      top: 50%;
      margin-top: -2px;
      display: block;
    }
    &__inner,
    &__inner:before,
    &__inner:after {
      width: 29px;
      height: 2px;
      background: var(--deco-color);
      position: absolute;
      transition: transform .15s ease;
    }
    &__inner:before,
    &__inner:after {
      content: "";
      display: block;
    }
    &__inner:before {
      top: -8px;
    }
    &__inner:after {
      top: -16px;
    }
    &--collapse &__inner {
      top: auto;
      bottom: 0;
      transition: transform .13s cubic-bezier(.55, .055, .675, .19) .13s, background var(--primary-transition);
      &:after {
        transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear, background var(--primary-transition);
      }
      &:before {
        transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19), background var(--primary-transition);
      }
    }
    &--collapse.active &__inner,
    a.active &--collapse &__inner {
      background: var(--elements-color);
      transform: translate3d(0, -7px, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      &:after {
        top: 0;
        opacity: 0;
        background: var(--elements-color);
        transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
      }
      &:before {
        top: 0;
        background: var(--elements-color);
        transform: rotate(-90deg);
        transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
      }
    }
  }
  &-prize {
    width: 100%;
    margin: 0 0 30px 0;
    display: flex;
    &__image,
    &__video {
      width: 50%;
    }
  }
  &-box {
    width: 100%;
    background: rgba(0, 0, 0, .6);
    border: 1px dashed var(--cotton-seed);
    border-radius: 10px;
    padding: 22px 20px 0 20px;
    position: relative;
    &--info {
      width: calc(50% - 20px);
      h3 {
        font: 58px/1 var(--secondary-font);
        background: var(--black);
        margin: -54px 0 16px 0;
        float: left;
      }
    }
    &__deco {
      &-1 {
        position: absolute;
        right: 0;
        bottom: calc(100% + 1px);
        z-index: -1;
      }
      &-2 {
        position: absolute;
        left: 10px;
        bottom: calc(100% + 15px);
        z-index: -1;
      }
      &-3 {
        position: absolute;
        right: 0;
        bottom: calc(100% + 1px);
        z-index: -1;
      }
      &-4 {
        position: absolute;
        left: calc(100% + 1px);
        bottom: -1px;
        z-index: -1;
      }
    }
    p {
      font-size: 14px;
    }
    ol {
      &:not([class]) {
        font-size: 14px;
        line-height: 1.44;
        padding: 0 0 12px 0;
        li {
          padding: 0 0 10px 26px;
          position: relative;
          &:before {
            width: 18px;
            height: 18px;
            font-weight: 600;
            font-size: 11px;
            content: counter(li);
            border: 1px solid var(--secondary-deco-color);
            border-radius: 50%;
            box-sizing: border-box;
            padding: 4px 0 0 0;
            margin: 0 8px 0 0;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  &-image {
    width: 100%;
    margin: 0 0 30px 0;
  }
  &-side-image {
    transform: translateX(calc(-100% - 45px));
    position: absolute;
  }
  &-line {
    width: 52px;
    height: 2px;
    background: var(--deco-color);
    margin: 0 0 20px -22px;
    display: inline-block;
  }
}
.nav {
  &-main {
    width: 100%;
    max-width: 620px;
    opacity: 0;
    visibility: hidden;
    background: var(--deco-color);
    transition: all var(--primary-transition);
    border-radius: 0 0 0 50px;
    box-shadow: 0 0 30px 0 rgba(000, 00, 0, .56);
    padding: 90px 20px 45px 20px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    &.open {
      opacity: 1;
      visibility: visible;
    }
    &__links {
      color: var(--elements-color);
      font: 16px/1.2 var(--primary-font);
      text-align: center;
      text-decoration: none;
      transition: color var(--primary-transition);
      padding: 15px 0;
      display: block;
      &.active,
      &:hover {
        color: var(--black);
      }
    }
  }
}
.body {
  padding: 0 0 30px 0;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
}
.section {
  &--nutritional {
    background: url(../img/bg-nutritional.jpg) repeat center center / cover;
    padding: 56px 0 48px 0;
    margin: 0 0 38px 0;
  }
  &--steaks {
    min-height: 547px;
    background: url(../img/hero-didkepsniai.jpg) no-repeat center center / cover;
    margin: 0 0 60px 0;
    align-items: center;
    display: flex;
  }
  &--minced {
    min-height: 620px;
    color: var(--black);
    background: url(../img/hero-farsas.jpg) no-repeat center center / cover;
    margin: 0 0 60px 0;
    display: flex;
  }
  &--barbecue {
    min-height: 537px;
    background: url(../img/hero-saslykai.jpg) no-repeat center center / cover;
    margin: 0 0 60px 0;
    align-items: center;
    display: flex;
  }
  &--knife {
    min-height: 585px;
    background: url(../img/hero-peilis.jpg) no-repeat center center / cover;
    margin: 0 0 94px 0;
    position: relative;
    align-items: center;
    display: flex;
    &::after {
      height: 128px;
      content: "";
      background: url(../img/hero-peilis-deco.png) no-repeat center center / contain;
      position: absolute;
      inset: auto 0 -34px 0;
    }
  }
  &--tricks {
    background: url(../img/hero-tricks.jpg) no-repeat calc(50% + 400px) top;
    padding: 65px 0 0 0;
  }
}
.info {
  &-logo {
    width: 28.417%;
    margin: 90px 0 0 0;
    align-self: flex-start;
  }
  &-text {
    width: 34%;
    min-height: 310px;
    margin: 90px 115px 0 auto;
    align-self: flex-start;
  }
  &-hold {
    padding: 182px 0 110px 0;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 80px 38px;
  }
  &-box {
    border-radius: var(--primary-radius);
    padding: 26px 35px 4px 35px;
    margin: 0 0 22px 0;
    &--blue {
      background: var(--blue-chill);
    }
    h3 {
      margin-bottom: 12px;
    }
  }
  &-columns {
    column-count: 2;
    column-gap: 50px;
    padding: 0 0 25px 0;
    &--multiple {
      column-count: 3;
    }
    &--center {
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      display: grid;
    }
    &__wrap {
      break-inside: avoid-column;
      &--border {
        border: 1px solid var(--deco-color);
        padding: 0 35px 10px 35px;
        margin: 30px 0 40px 0;
      }
      &--headline-1 {
        h2 {
          margin: 0 0 30px 120px;
        }
        h3 {
          font: clamp(60px, 8vw, 124px)/1 var(--secondary-font);
          margin: 0;
        }
      }
      &--list-1 {
        ol {
          margin: 0 0 20px 0;
          li {
            padding: 0 0 25px 45px;
            position: relative;
            &:before {
              font: 600 clamp(40px, 8vw, 55px)/1 var(--Austin);
              content: counter(li);
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
    &__headline {
      font: 600 14px/1.2 var(--primary-font);
      background: var(--black);
      border: 1px solid var(--elements-color);
      outline: 20px solid var(--black);
      padding: 13px 20px 9px 20px;
      margin: 0;
      position: relative;
      top: -20px;
      display: inline-flex;
    }
    h5 {
      margin-bottom: 6px;
    }
  }
  &-list {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    @media (min-width: $desktop-min) {
      .promo & {
        max-width: 388px;
      }
    }
    &__items {
      font: 18px/1.2 var(--primary-font);
      border-bottom: 1px dashed transparent;
      padding: 16px 0 10px 0;
      align-items: center;
      justify-content: space-between;
      display: flex;
      gap: 15px;
      &:last-child {
        border-bottom: none;
      }
    }
    &--white {
      border-color: var(--white);
      margin-bottom: 45px;
    }
    &--white &__items {
      border-color: var(--white);
      *:last-child {
        color: var(--blue-chill);
      }
    }
    &--black {
      border-color: var(--black);
      margin-bottom: 15px;
    }
    &--black &__items {
      border-color: var(--black);
    }
    &--matterhorn {
      border-color: var(--matterhorn);
      margin-bottom: 55px;
    }
    &--matterhorn &__items {
      border-color: var(--matterhorn);
    }
    &--green {
      border-color: var(--green-smoke);
      margin-bottom: 45px;
    }
    &--green &__items {
      border-color: var(--green-smoke);
    }
    &--orange {
      border-color: var(--international-orange);
      margin-bottom: 45px;
    }
    &--orange &__items {
      border-color: var(--international-orange);
    }
  }
}
.hero {
  &-steaks {
    &__text {
      max-width: 360px;
      text-align: center;
      margin: 0 170px 0 auto;
      h3 {
        font: clamp(60px, 8vw, 124px)/1 var(--secondary-font);
        margin-top: -20px;
      }
    }
  }
  &-minced {
    &__text {
      max-width: 380px;
      h3 {
        font: clamp(60px, 8vw, 124px)/1 var(--secondary-font);
      }
    }
    &__image {
      max-width: 95%;
      margin: auto 0 -15px auto;
    }
  }
  &-barbecue {
    &__text {
      max-width: 100%;
      margin: 0 0 0 100px;
    }
  }
  &-knife {
    &__text {
      max-width: 350px;
      margin: 0 0 0 auto;
      h3 {
        font: clamp(60px, 8vw, 124px)/1 var(--secondary-font);
        margin: -20px 0 45px 30px;
      }
    }
  }
}
.nutritional {
  &-text {
    padding: 8px 0 0 0;
    align-self: flex-start;
    flex: 1 1 0;
  }
  &-col {
    width: 174px;
    margin: 0 0 0 50px;
    align-self: flex-start;
    &__image {
      height: 59px;
      margin: 0 0 13px 0;
      align-items: flex-end;
      display: flex;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__list {
      display: flex;
      flex-flow: column nowrap;
      gap: 6px;
      &__items {
        font: 14px/1.2 var(--primary-font);
        align-items: center;
        justify-content: space-between;
        display: flex;
        gap: 10px;
      }
    }
  }
}
.tabs {
  &-nav {
    width: 244px;
    margin: 20px -60px 0 0;
    position: sticky;
    top: 20px;
    z-index: 1;
    align-self: flex-start;
    &__links {
      color: var(--secondary-text-color);
      font: 18px/1.2 var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      border-radius: var(--primary-radius);
      padding: 23px 50px 19px 20px;
      display: block;
      &--mobile {
        display: none;
      }
      &.active {
        color: var(--text-color);
        background: url(../img/icon-arrow-right.svg) no-repeat var(--tuna) right 22px center;
      }
      &:hover {
        color: var(--text-color);
      }
    }
  }
  &-hold {
    width: 997px;
    margin: 0 0 0 auto;
    align-self: flex-start;
  }
  &-panels {
    transition: all var(--primary-transition);
    grid-template-rows: 0fr;
    display: grid;
    &.open {
      grid-template-rows: 1fr;
    }
    &__hold {
      overflow: hidden;
    }
    &__text {
      padding: 0 200px 0 100px;
    }
  }
}
.animal {
  &-parts {
    $parent: &;
    &--pork {
      margin: 30px 0;
      #{$parent}__image {
        max-width: 100%;
        margin: 0 auto;
        display: block;
      }
      #{$parent}__hold {
        counter-reset: h3;
        column-count: 2;
        column-gap: 60px;
        padding: 60px 0 0 0;
        h3 {
          padding-top: 5px;
          margin-bottom: 3px;
          align-items: flex-start;
          display: flex;
          &:before {
            height: 22px;
            color: var(--text-color);
            font: 600 12px/1.5 var(--primary-font);
            content: counter(h3);
            counter-increment: h3;
            background: var(--deco-color);
            border-radius: 50%;
            margin: -2px 8px 0 0;
            align-items: flex-end;
            justify-content: center;
            display: inline-flex;
            flex: 0 0 22px;
          }
        }
      }
      #{$parent}__wrap {
        break-inside: avoid-column;
      }
    }
    &--beef {
      margin: 30px 0 50px 0;
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 46px;
      #{$parent}__image {
        max-width: 69.765%;
      }
      ol {
        &:not([class]) {
          line-height: 1.44;
          margin-top: 44px;
          li {
            padding: 0 0 0 26px;
            position: relative;
            &:before {
              width: 18px;
              height: 18px;
              font-weight: 600;
              font-size: 11px;
              content: counter(li);
              border: 1px solid var(--deco-color);
              border-radius: 50%;
              box-sizing: border-box;
              padding: 4px 0 0 0;
              margin: 0 8px 0 0;
              align-items: center;
              justify-content: center;
              display: inline-flex;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
    &--chicken {
      margin: 20px 0 40px 0;
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 10px 40px;
      #{$parent}__hold {
        counter-reset: h3;
        padding: 30px 0 0 0;
        flex: 1 1 0;
        h3 {
          padding-top: 5px;
          margin-bottom: 3px;
          align-items: flex-start;
          display: flex;
          &:before {
            height: 22px;
            color: var(--text-color);
            font: 600 12px/1.5 var(--primary-font);
            content: counter(h3);
            counter-increment: h3;
            background: var(--deco-color);
            border-radius: 50%;
            margin: -2px 8px 0 0;
            align-items: flex-end;
            justify-content: center;
            display: inline-flex;
            flex: 0 0 22px;
          }
        }
      }
      #{$parent}__image {
        max-width: none;
      }
    }
  }
}
.description {
  &-block {
    margin: 0 0 65px 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 70px 20px;
    &--beef {
      background: url(../img/description-jautiena.jpg) no-repeat center center;
    }
    &--meet {
      background: url(../img/description-mesa.jpg) no-repeat center calc(50% - 50px);
    }
  }
}
.locations {
  &-list {
    background: var(--eucalyptus);
    border-radius: var(--primary-radius);
    padding: 38px 90px 20px 90px;
    margin: 0 0 25px 0;
    ol:not([class]) {
      column-count: 2;
      column-gap: 80px;
    }
  }
}
.promo {
  position: relative;
  &-1,
  &-3 {
    margin: 0 0 38px 0;
  }
  &-1 &__image,
  &-3 &__image {
    width: 100%;
  }
  &-1 &__text,
  &-3 &__text {
    max-width: 380px;
    position: absolute;
    inset: 20px auto 20px 100px;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    h3 {
      font: clamp(60px, 8vw, 124px)/1 var(--secondary-font);
    }
    & > *:last-child {
      padding-bottom: 0;
    }
  }
  &-1 &__text {
    h3 {
      margin: 0 0 6px 0;
    }
  }
  &-3 &__text {
    h3 {
      margin: 0 0 6px -28px;
    }
  }
  &-3 &__headline {
    margin: 0 0 6px -18px;
    align-items: flex-end;
    display: flex;
    gap: 15px;
    &__label {
      color: var(--black);
      font: 500 24px/1 var(--primary-font);
      text-transform: uppercase;
      background: var(--deco-color);
      padding: 9px 10px 4px 10px;
      margin: 0 0 8px 0;
      display: inline-block;
    }
  }
  &-2,
  &-4,
  &-5,
  &-6,
  &-7,
  &-8,
  &-9,
  &-transparent {
    border-radius: var(--primary-radius);
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  &-2 {
    min-height: 330px;
    color: var(--black);
    background: var(--green-smoke);
    padding: 38px 30px 38px 100px;
    margin: 50px 0 60px 0;
    gap: 10px 60px;
  }
  &-4 {
    min-height: 330px;
    color: var(--text-color);
    background: var(--matterhorn);
    padding: 38px 30px 38px 100px;
    margin: 40px 0 60px 0;
    gap: 10px 110px;
  }
  &-5 {
    min-height: 330px;
    color: var(--black);
    background: var(--flame-pea);
    padding: 38px 30px 38px 100px;
    margin: 40px 0 60px 0;
    gap: 10px 30px;
  }
  &-6 {
    min-height: 330px;
    color: var(--text-color);
    background: var(--gun-powder);
    padding: 38px 30px 38px 100px;
    margin: 20px 0 25px 0;
    gap: 10px 20px;
  }
  &-7 {
    min-height: 330px;
    color: var(--text-color);
    background: var(--mine-shaft);
    padding: 38px 30px 38px 100px;
    margin: 20px 0 25px 0;
    gap: 10px 20px;
  }
  &-8 {
    color: var(--text-color);
    background: var(--blue-chill);
    padding: 38px 30px 38px 100px;
    margin: 20px 0 70px 0;
    gap: 10px 50px;
  }
  &-9 {
    color: var(--black);
    background: var(--flame-pea);
    padding: 38px 100px 38px 100px;
    margin: 300px 0 60px 0;
    gap: 10px 50px;
  }
  &-transparent {
    min-height: 330px;
    color: var(--text-color);
    padding: 38px 30px 38px 100px;
    margin: 20px 0 50px 0;
    gap: 10px 50px;
  }
  &-2 &__text,
  &-4 &__text,
  &-5 &__text,
  &-6 &__text,
  &-7 &__text,
  &-8 &__text,
  &-9 &__text,
  &-transparent &__text {
    width: 100%;
    flex: 1 1 0;
    & > *:last-child {
      padding-bottom: 0;
    }
    &__columns,
    &__rows {
      color: var(--casper);
      padding: 0 0 18px 0;
      p {
        padding-bottom: 0;
      }
    }
    &__columns {
      column-count: 2;
      column-gap: 20px;
    }
  }
  &-2 &__text,
  &-4 &__text,
  &-5 &__text,
  &-6 &__text,
  &-7 &__text {
    h3 {
      font: 26px/1.26 var(--primary-font);
      margin: 0 0 -8px 0;
    }
    h6 {
      margin: 0 0 10px 0;
    }
  }
  &-8 &__text {
    h2 {
      font: clamp(60px, 8vw, 87px)/1 var(--secondary-font);
      margin: 0 0 5px 8px;
    }
    h3 {
      font: clamp(46px, 6.3vw, 66px)/1 var(--secondary-font);
      margin: 0;
    }
  }
  &-6 &__text,
  &-7 &__text {
    ul:not([class]),
    ol:not([class]) {
      font-size: 14px;
    }
  }
  &-9 &__text {
    column-count: 2;
    column-gap: 96px;
  }
  &-2 &__headline,
  &-4 &__headline,
  &-5 &__headline,
  &-6 &__headline,
  &-7 &__headline {
    margin: 0 0 32px 0;
    align-items: flex-end;
    display: flex;
    gap: 20px;
  }
  &-2 &__image,
  &-4 &__image,
  &-5 &__image,
  &-6 &__image,
  &-7 &__image,
  &-transparent &__image {
    max-width: 50%;
    border: 6px solid var(--cotton-seed);
  }
  &-2 &__image,
  &-6 &__image,
  &-7 &__image {
    margin: -100px 0 0 auto;
    align-self: flex-start;
  }
  &-4 &__image {
    margin: 0 0 -100px auto;
    align-self: flex-end;
  }
  &-5 &__image,
  &-transparent &__image {
    margin: -100px auto;
    align-self: center;
  }
  &-8 &__image {
    max-width: 50%;
    border: 6px solid var(--white);
    margin: 0 0 -70px auto;
    align-self: flex-end;
  }
  &-9 &__image {
    max-width: 100%;
    border: 6px solid var(--cotton-seed);
    margin: -315px auto 34px auto;
    align-self: flex-start;
  }
  &-10 {
    min-height: 440px;
    background: url(../img/promo-kaip-šaldyti-mėsą.jpg) no-repeat center center / cover;
    padding: 20px 0;
    margin: 40px 0 50px 0;
    align-items: center;
    display: flex;
    .main {
      gap: 100px 300px;
    }
  }
  &-11 {
    min-height: 440px;
    background: url(../img/promo-mėsos-marinavimo-gudrybės.jpg) no-repeat center center / cover;
    padding: 20px 0;
    margin: 40px 0 50px 0;
    align-items: center;
    display: flex;
    .main {
      gap: 100px 300px;
    }
  }
  &-10 &__text,
  &-11 &__text {
    max-width: 295px;
    color: var(--black);
  }
  &-10 &__info {
    max-width: 310px;
    background: var(--secondary-deco-color);
    padding: 0 22px;
    margin: 0 0 0 auto;
  }
  &-10 &__info__headline {
    color: var(--black);
    font: 600 14px/1.2 var(--primary-font);
    border: 1px solid var(--black);
    padding: 13px 20px 9px 20px;
    margin-top: -30px;
    float: right;
    display: inline-flex;
  }
  &-12 {
    min-height: 496px;
    background: url(../img/promo-marinatą-paruoškite-kitame-inde-ir-tik-tada-įmasažuokite-į-mėsą.jpg) no-repeat center center / cover;
    padding: 20px 0;
    margin: 10px 0 80px 0;
    align-items: center;
    display: flex;
    .main {
      justify-content: flex-end;
      gap: 55px 45px;
    }
  }
  &-12 &__column {
    max-width: 320px;
  }
  &-12 &__text {
    text-align: center;
    padding: 0 0 35px 0;
    h2 {
      font: clamp(60px, 8vw, 82px)/1 var(--secondary-font);
    }
    h3 {
      font: 24px/1.4 var(--primary-font);
    }
  }
  &-12 &__info {
    width: 100%;
    padding: 0 22px;
  }
  &-12 &__info--1 {
    color: var(--black);
    background: var(--deco-color);
  }
  &-12 &__info--2 {
    color: var(--elements-color);
    background: rgba(0, 0, 0, .60);
  }
  &-12 &__info__headline {
    color: var(--elements-color);
    font: 600 14px/1.2 var(--primary-font);
    padding: 13px 20px 9px 20px;
    margin-top: -20px;
    float: left;
    display: inline-flex;
  }
  &-12 &__info--1 &__info__headline {
    background: var(--black);
  }
  &-12 &__info--2 &__info__headline {
    background: var(--deco-color);
  }
}
.flavors {
  &-pepper {
    &__top {
      text-align: center;
      background: url(../img/flavors-pepper-top.jpg) no-repeat center bottom / contain;
      padding: 0 0 110px 0;
      h3 {
        font: clamp(54px, 5vw, 75px)/1.2 var(--Austin);
      }
      p {
        max-width: 45ch;
        display: inline-block;
      }
    }
    &__list {
      color: var(--black);
      background: var(--elements-color);
      border-radius: 20px 20px 0 0;
      padding: 40px 130px 100px 130px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      display: grid;
      gap: 20px 60px;
      &__items {
        align-items: flex-start;
        display: flex;
        flex-flow: column nowrap;
        h6 {
          color: var(--elements-color);
          background: var(--black);
          padding: 7px 7px 2px 7px;
          display: inline-flex;
        }
      }
      &__image {
        max-width: 100%;
        margin: 0 0 20px 0;
        align-self: center;
      }
    }
    &__block {
      color: var(--black);
      background: var(--alabaster);
      align-items: center;
      display: flex;
      gap: 0 50px;
      &--top {
        padding: 70px 0 30px 0;
      }
      &--bottom {
        border-radius: 0 0 20px 20px;
      }
      &__text {
        &--1 {
          margin: 0 0 0 80px;
          h2 {
            font: clamp(60px, 8vw, 124px)/1 var(--secondary-font);
          }
          h3 {
            font: clamp(28px, 5vw, 44px)/1.3 var(--primary-font);
          }
        }
        &--2 {
          max-width: 465px;
          margin: 0 80px 0 auto;
        }
      }
      &__info {
        max-width: 345px;
        color: var(--elements-color);
        background: var(--secondary-deco-color);
        padding: 33px 40px 12px 40px;
        margin: 0 0 80px 80px;
      }
      &__image {
        text-align: right;
        margin: 0 50px 0 auto;
        align-self: flex-end;
        flex: 1 1 0;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
.slider {
  &-knife {
    counter-reset: h6;
    background: var(--shark);
    border-radius: 20px;
    padding: 60px 0 50px 0;
    margin: 0 0 50px 0;
    &__image {
      height: 55px;
      margin: 0 0 26px 0;
      align-items: center;
      display: flex;
      @media (max-width: 399px) {
        justify-content: center;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__items {
      @media (max-width: 399px) {
        text-align: center;
      }
      h6 {
        font-weight: 600;
        padding: 0 0 0 32px;
        position: relative;
        display: inline-flex;
        &:before {
          width: 24px;
          height: 24px;
          color: var(--text-color);
          font: 600 14px/20px var(--primary-font);
          content: counter(h6);
          counter-increment: h6;
          background: var(--abbey);
          border-radius: 50%;
          align-items: flex-end;
          justify-content: center;
          display: inline-flex;
          position: absolute;
          top: -5px;
          left: 0;
        }
      }
    }
  }
}
.tricks {
  &-text {
    max-width: 100%;
  }
  &-list {
    padding: 0 0 70px 0;
    counter-reset: li;
    grid-template-columns: 1.1fr 1fr 1.3fr;
    display: grid;
    gap: 30px;
    &__items {
      align-items: flex-start;
      display: flex;
      gap: 10px;
      &--1 {
        padding: 70px 0 0 0;
      }
      &--2 {
        padding: 125px 0 0 0;
      }
      &:before {
        color: var(--deco-color);
        font: clamp(60px, 8vw, 124px)/1 var(--ArnoPro);
        content: counter(li) ".";
        counter-increment: li;
        margin: -10px 0 0 0;
      }
    }
  }
}
.random {
  &-1 {
    padding: 0 0 70px 0;
    align-items: center;
    display: flex;
    gap: 20px 50px;
  }
  &-1 &__text {
    max-width: 350px;
    margin: 30px 0 0 0;
  }
  &-1 &__image {
    max-width: 57%;
    margin: 0 0 0 auto;
  }
}
.btn {
  text-align: center;
  text-decoration: none;
  transition: all var(--primary-transition);
  align-items: center;
  justify-content: center;
  display: inline-flex;
  gap: 12px;
  &--regular {
    color: var(--deco-color);
    font: 18px/24px var(--primary-font);
    background: none;
    border: 1px solid var(--deco-color);
    border-radius: var(--primary-radius);
    padding: 14px 22px 10px 22px;
    &:hover {
      color: var(--text-color);
      background: var(--deco-color);
    }
  }
  &--block {
    width: 100%;
  }
}
// imports bottom
@import "tablet";
@import "mobile";
@import "helpers";