h1,
.h1 {
  font: 54px/1.1 var(--primary-font);
  margin: 0 0 20px 0;
}
h2,
.h2 {
  font: clamp(28px, 5vw, 34px)/1.3 var(--primary-font);
  margin: 0 0 22px 0;
}
h3,
.h3 {
  font: 21px/1.2 var(--primary-font);
  margin: 0 0 22px 0;
}
h4,
.h4 {
  font: 500 18px/1.2 var(--primary-font);
  margin: 0 0 22px 0;
}
h5,
.h5 {
  font: 500 16px/1.2 var(--primary-font);
  margin: 0 0 22px 0;
}
h6,
.h6 {
  font: 500 14px/1.2 var(--primary-font);
  margin: 0 0 22px 0;
}
.headline {
  &-hyper {
    font: 100px/1 var(--primary-font);
  }
  &-line {
    position: relative;
    &:before {
      width: 42px;
      height: 1px;
      content: "";
      background: var(--deco-color);
      position: absolute;
      top: calc(50% - 3px);
      left: -62px;
      @media (max-width: $mobile-max) {
        display: none;
      }
    }
  }
}
ul,
ol {
  &:not([class]) {
    font: 16px/1.37 var(--primary-font);
    padding: 0 0 22px 0;
    li {
      padding: 0 0 5px 0;
      &:last-child {
        padding-bottom: 0;
      }
      &:before {
        display: inline-block;
      }
    }
    & + h2 {
      padding-top: 40px;
    }
    ul:not([class]),
    ol:not([class]) {
      padding: 5px 0 0 0;
      margin-left: 20px;
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 5px;
        height: 5px;
        content: "";
        background: var(--text-color);
        border-radius: 50%;
        margin: 0 8px 0 0;
        position: relative;
        top: -2px;
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    & > li {
      &:before {
        content: counter(li) ".";
        counter-increment: li;
        margin: 0 5px 0 0;
      }
    }
  }
}
p {
  font: 16px/1.37 var(--primary-font);
  padding: 0 0 22px 0;
  &.smaller {
    font-size: 14px;
  }
  &.smallest {
    font-size: 12px;
  }
  & + h2 {
    padding-top: 20px;
  }
}
a {
  &:not([class]) {
    color: var(--deco-color);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &[href^="mailto:"],
  &[href^="http"] {
    word-break: break-all;
  }
  &:focus-visible {
    outline: 1px solid var(--deco-color);
  }
}
.text-color {
  color: var(--text-color) !important;
}
.text-color-secondary {
  color: var(--secondary-text-color) !important;
}
.text-color-deco {
  color: var(--deco-color) !important;
}
.text-color-deco-secondary {
  color: var(--secondary-deco-color) !important;
}
.text-color-deco-tertiary {
  color: var(--tertiary-deco-color) !important;
}
.text-color-error {
  color: var(--error-color) !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-light {
  font-weight: 300 !important;
}
.text-normal {
  font-weight: normal !important;
}